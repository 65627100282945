import { ApolloError } from '@apollo/client';
import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

import {
  CreateCurrentCartMutation,
  useCreateCurrentCartMutation,
} from '@/core/graphql/mutations/CreateCurrentCart/CreateCurrentCart.delio.generated';
import { useCurrentCart } from '@/core/hooks/useCurrentCart';
import { useShippingAddress } from '@/core/hooks/useShippingAddress';
import { selectCartItemTotal } from '@/core/selectors/selectCartItemTotal';
import { sendProductAddedEvent } from '@/modules/analytics/eventHandlers/sendProductAddedEvent';
import { addToProductsInCartGroup } from '@/modules/analytics/groupHandlers/productsInCartGroup';
import { AUTH_ROLE } from '@/modules/auth/contexts/AuthContext';
import { useFirebaseAuthClient } from '@/modules/auth/hooks/useFirebaseAuthClient';
import { useSession } from '@/modules/auth/hooks/useSession';
import { AddedLineItem } from '@/modules/cart/types';
import { useUpsellSectionContext } from '@/modules/checkout/components/UpsellModal/components/UpsellProductSection/contexts/UpsellSectionContext/useUpsellSectionContext';
import { useLocationModal } from '@/modules/location/hooks/useLocationModal';
import { ShippingAddressInput } from '@/types/gqlCommon.delio.generated';
import { updateDeep } from '@lib/api/update-deep';

import { useAddLineItemToMyCart } from './useAddLineItemToMyCart';

type CurrentCart = CreateCurrentCartMutation['createCart'];

export const useAddLineItemToCurrentCart = () => {
  const { openLocationModal } = useLocationModal();
  const authRole = useSession('authRole');
  const createSession = useSession('createSession');
  const firebaseAuthClient = useFirebaseAuthClient();
  const currentCartResult = useCurrentCart();
  const shippingAddressResult = useShippingAddress();
  const [loading, setLoading] = useState(false);
  const [createCart] = useCreateCurrentCartMutation();
  const { asPath, query } = useRouter();
  const upsellSectionContext = useUpsellSectionContext();

  const [addLineItemToMyCart] = useAddLineItemToMyCart();

  const createNewCart = useCallback(
    async (
      shippingAddress: ShippingAddressInput
    ): Promise<CurrentCart | undefined> => {
      const newCurrentCart = await createCart({
        variables: {
          cart: { shippingAddress },
        },
      });

      return newCurrentCart?.data?.createCart;
    },
    [createCart]
  );

  const updateCartCache = useCallback(
    (currentCart: CurrentCart) => {
      currentCartResult.updateQuery((prev) =>
        updateDeep(prev, { currentCart })
      );
    },
    [currentCartResult]
  );

  const addLineItemsToCurrentCartAndSendAnalytics = useCallback(
    async (
      lineItems: AddedLineItem[],
      currentCart: CurrentCart,
      attributionToken?: string
    ) => {
      const cart = await addLineItemToMyCart(lineItems, currentCart);

      if (cart) {
        const freeDeliveryLevel = Number(
          cart.shippingMethod.price.freeAbove?.centAmount
        );
        const cartRegularPrice = selectCartItemTotal(cart.lineItems);
        const isFreeDelivery = cartRegularPrice >= freeDeliveryLevel;
        const freeDeliveryMissingValue = isFreeDelivery
          ? 0
          : freeDeliveryLevel - cartRegularPrice;

        const attrToken = query?.attributionToken
          ? String(query.attributionToken)
          : undefined;

        sendProductAddedEvent({
          cartId: cart.id,
          lineItems: cart.lineItems,
          freeDelivery: isFreeDelivery,
          freeDeliveryMissingValue,
          route: asPath,
          attributionToken: attributionToken ?? attrToken,
          addedLineItems: lineItems,
          sectionName: upsellSectionContext?.sectionName,
        });

        addToProductsInCartGroup();
      }

      return cart;
    },
    [
      addLineItemToMyCart,
      asPath,
      query.attributionToken,
      upsellSectionContext?.sectionName,
    ]
  );

  const refreshCartAndAddProductToCart = useCallback(
    async (lineItems: AddedLineItem[]) => {
      try {
        const {
          data,
          loading: isLoading,
          error,
        } = await currentCartResult.refetch();

        if (data && !isLoading && !error) {
          const currentCart = data?.currentCart;

          if (!currentCart) return;

          await addLineItemsToCurrentCartAndSendAnalytics(
            lineItems,
            currentCart
          );

          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        throw err;
      }
    },
    [addLineItemsToCurrentCartAndSendAnalytics, currentCartResult]
  );

  const getCurrentCart = useCallback(async (): Promise<
    CurrentCart | undefined
  > => {
    if (!shippingAddressResult.data) return;

    const result =
      currentCartResult.data?.currentCart ||
      (await createNewCart(shippingAddressResult.data));

    if (result) {
      updateCartCache(result);
    }

    return result;
  }, [
    createNewCart,
    currentCartResult.data?.currentCart,
    shippingAddressResult.data,
    updateCartCache,
  ]);

  const addLineItemsToCurrentCart = useCallback(
    async (lineItems: AddedLineItem[], attributionToken?: string) => {
      if (shippingAddressResult.loading) return;

      if (!shippingAddressResult.data?.postalCode) {
        return openLocationModal();
      }

      try {
        setLoading(true);

        if (
          authRole === AUTH_ROLE.UNAUTHENTICATED ||
          authRole === AUTH_ROLE.NOT_CONFIRMED
        ) {
          await createSession({
            refreshTokenPromise: firebaseAuthClient.signInAnonymously(),
          });
        }

        const currentCart = await getCurrentCart();

        if (!currentCart) return;

        const cart = await addLineItemsToCurrentCartAndSendAnalytics(
          lineItems,
          currentCart,
          attributionToken
        );

        setLoading(false);

        return cart;
      } catch (err) {
        if (err instanceof ApolloError) {
          const [error] = err.graphQLErrors;

          if (error?.extensions?.code === 'CART_ALREADY_EXISTS') {
            await refreshCartAndAddProductToCart(lineItems);
            return;
          }
        }

        captureException(err);
        setLoading(false);
        throw err;
      }
    },
    [
      addLineItemsToCurrentCartAndSendAnalytics,
      authRole,
      createSession,
      firebaseAuthClient,
      getCurrentCart,
      openLocationModal,
      refreshCartAndAddProductToCart,
      shippingAddressResult.data?.postalCode,
      shippingAddressResult.loading,
    ]
  );

  return [addLineItemsToCurrentCart, loading] as const;
};
