import { UrlObject } from 'url';

import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

/**
 * It returns a tuple `[routerActionStatus, routerActionFn]`.
 *
 * E.g.: `const [replaceStatus, replace] = useRouterAction("replace")`
 *
 * It helps to handle asynchronous router transitions properly.
 * */
export const useRouterAction = (action: 'push' | 'replace' | 'back') => {
  const [status, setStatus] = useState<RouterActionStatus>('IDLE');

  const router = useRouter();
  const routerAction = router[action];

  const callRouterAction = useCallback(
    async (
      url: string | UrlObject,
      as?: string | UrlObject,
      options?: {
        shallow?: boolean;
        locale?: string | false;
        scroll?: boolean;
      }
    ) => {
      try {
        setStatus('LOADING');
        await routerAction(url, as, {
          // Most of the time we're going to use the shallow routing since we'd
          // to avoid like unnecessary server requests.
          // Only page changes should cause server requests, and it's the
          // default behavior of the shallow route transition.
          // https://nextjs.org/docs/routing/shallow-routing#caveats
          shallow: true,
          ...options,
        });
        setStatus('RESOLVED');
      } catch (err) {
        console.error(err);
        setStatus('REJECTED');
      }
    },
    [routerAction]
  );

  return [status, callRouterAction] as const;
};

export type RouterActionStatus = 'IDLE' | 'LOADING' | 'REJECTED' | 'RESOLVED';
