import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { UpdateCurrentCartMutation } from '@/core/graphql/mutations/UpdateCurrentCart/UpdateCurrentCart.delio.generated';
import { useCurrentCart } from '@/core/hooks/useCurrentCart';
import { useUpdateCurrentCartMutation } from '@/core/hooks/useUpdateCurrentCartMutation';
import { selectCartItemTotal } from '@/core/selectors/selectCartItemTotal';
import { sendProductRemovedEvent } from '@/modules/analytics/eventHandlers/sendProductRemovedEvent';
import { removeFromProductsInCartGroup } from '@/modules/analytics/groupHandlers/productsInCartGroup';
import { useUpsellSectionContext } from '@/modules/checkout/components/UpsellModal/components/UpsellProductSection/contexts/UpsellSectionContext/useUpsellSectionContext';
import { isTruthy } from '@lib/api/is-truthy';

type Args = {
  removeLineItems: { sku: string; quantity?: number }[];
  attributionToken?: string;
  onCompleted?: (response: UpdateCurrentCartMutation) => void;
};

export const useRemoveLineItemsFromCurrentCart = () => {
  const { t } = useTranslation('product');
  const currentCartResult = useCurrentCart();
  const currentCart = currentCartResult.data?.currentCart;
  const [updateCurrentCart, removeLineItemFromCurrentCartResult] =
    useUpdateCurrentCartMutation();
  const upsellSectionContext = useUpsellSectionContext();

  const { asPath, query } = useRouter();

  const removeLineItemFromCurrentCart = useCallback(
    async ({ removeLineItems, attributionToken, onCompleted }: Args) => {
      if (!currentCart) return;

      const itemsToRemove = removeLineItems
        .map(({ quantity, sku }) => {
          const item = currentCart.lineItems.find(
            (el) => el.product.sku === sku
          );
          if (!item) return null;
          const totalQuantity = item.quantity;
          return {
            quantity: quantity ? -quantity : -totalQuantity,
            sku,
          };
        })
        .filter(isTruthy);

      await updateCurrentCart({
        variables: {
          cartId: currentCart.id,
          actions: [
            ...itemsToRemove.map(({ quantity, sku }) => ({
              AddLineItem: {
                quantity,
                sku,
              },
            })),
          ],
        },
        onCompleted: (response) => {
          onCompleted?.(response);

          const cart: UpdateCurrentCartMutation['updateCart'] =
            response.updateCart;
          if (!cart) return;

          removeLineItems.forEach(({ sku }) => {
            const item = currentCart.lineItems.find(
              (el) => el.product.sku === sku
            );
            if (item) {
              const freeDeliveryLevel = Number(
                cart.shippingMethod.price.freeAbove?.centAmount
              );
              const cartRegularPrice = selectCartItemTotal(cart.lineItems);
              const isFreeDelivery = cartRegularPrice >= freeDeliveryLevel;
              const freeDeliveryMissingValue = isFreeDelivery
                ? 0
                : freeDeliveryLevel - cartRegularPrice;

              const attrToken = query?.attributionToken
                ? String(query.attributionToken)
                : undefined;

              sendProductRemovedEvent({
                cartId: cart.id,
                lineItem: item,
                freeDeliveryMissingValue,
                freeDelivery: isFreeDelivery,
                route: asPath,
                attributionToken: attributionToken ?? attrToken,
                sectionName: upsellSectionContext?.sectionName,
              });
            }
          });
          if (cart.lineItems.length === 0) {
            removeFromProductsInCartGroup();
          }
        },
        onError: () => {
          toast.error(t('Cannot remove product from the cart'));
        },
      });
    },
    [
      asPath,
      currentCart,
      query.attributionToken,
      t,
      updateCurrentCart,
      upsellSectionContext?.sectionName,
    ]
  );

  return [
    removeLineItemFromCurrentCart,
    removeLineItemFromCurrentCartResult,
  ] as const;
};
